import { useEffect } from "react";
import { amountToDollars } from "../../../../../utils";


export const Confirm = ({ getValues, register, services, total, setStep, setCanStep, formState: { errors, touchedFields } }) => {

  useEffect(() => {
    setCanStep(true);
  }, [setCanStep]);

  const selectedServices = getValues('services');
  const serviceItems = selectedServices ? selectedServices.map(s => {
    return <li key={s}>{services[s].service} - ${amountToDollars(services[s].charge)}</li>
  }) : [];

  const requestedDrawItems =  getValues('drawItems');
  const drawItems = requestedDrawItems ? requestedDrawItems.map((d, i) => {
    return <li key={`draw-item-${i}`}>{d.item}: {d.description} - ${amountToDollars(d.budget)}</li>
  }) : [];

  const handleEditClick = (step) => {
    setStep(step);
  }

  return <div className='flex flex-col space-y-3 p-10 pt-10'>
    <h1>Services <button className="text-blue-500" onClick={() => handleEditClick(1)}>Edit</button></h1>
    <span className="block text-xs font-medium text-slate-gray pb-1">
      <ul>
        {serviceItems}
      </ul>
    </span>

    <h1>Draw Items <button className="text-blue-500" onClick={() => handleEditClick(2)}>Edit</button></h1>
    <span className="block text-xs font-medium text-slate-gray pb-1">
      <ul>
        {drawItems}
      </ul>
    </span>

    <h1>Property Owner <button className="text-blue-500" onClick={() => handleEditClick(3)}>Edit</button></h1>
    <span className="block text-xs font-medium text-slate-gray pb-1">
      {getValues('propertyOwner')}
    </span>

    <h1>Address <button className="text-blue-500" onClick={() => handleEditClick(4)}>Edit</button></h1>
    <span className="block text-xs font-medium text-slate-gray pb-1">
      {getValues('propertyAddressLine1')}
      {getValues('propertyAddressLine2')}
      {getValues('propertyCity')}, {getValues('propertyState')} {getValues('propertyPostalCode')}
    </span>

    <h1>Property Description <button className="text-blue-500" onClick={() => handleEditClick(5)}>Edit</button></h1>
    <span className="block text-xs font-medium text-slate-gray pb-1">
      {getValues('propertyDescription')}
    </span>

    <h1>Inspector Directions <button className="text-blue-500" onClick={() => handleEditClick(6)}>Edit</button></h1>
    <span className="block text-xs font-medium text-slate-gray pb-1">
      {getValues('directions')}
    </span>

    <h1>Total: ${amountToDollars(total)}</h1>

  </div>;
}