export const Welcome = () => {
  return <div className='flex flex-col space-y-3 p-10 pt-10'>
    <label>
      <span className="block text-xs font-medium text-slate-gray pb-1">
        Welcome to the Easy Hub Inspection Service!
        This service allows you to request various types of property inspections.
        Once requested, one of our inspectors will perform the inspection
        and you will receive an email with the inspection report when completed.
      </span>
    </label>
  </div>
}