import joi from 'joi';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';

export const useInspectionRequestForm = () => {
  const schema = joi.object({
    propertyOwner: joi.string().required(),
    propertyAddressLine1: joi.string().required(),
    propertyAddressLine2: joi.string().optional(),
    propertyCity: joi.string().required(),
    propertyState: joi.string().required(),
    propertyPostalCode: joi.string().regex(/^\d{5}(?:[-\s]\d{4})?$/).required().messages({
      'string.pattern.base': 'Please enter a valid postal code',
    }),
    propertyDescription: joi.string().optional(),
    directions: joi.string().optional(),
    services: joi.array().items(joi.string()).min(1).required(),
    drawItems: joi.array().items(joi.object({
      item: joi.string().required(),
      description: joi.string().required(),
      budget: joi.number().required()
    })).optional()
  });

  const form = useForm({
    resolver: joiResolver(schema),
  });
  
  return form;
}