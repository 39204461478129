import { useEffect } from "react";
import { isNullish } from "../../../../../utils";

export const PropAddress = ({ getValues, register, watch, setCanStep, formState: { errors, touchedFields } }) => {
  

  const [
    propertyAddressLine1,
    propertyCity,
    propertyState,
    propertyPostalCode,
  ] = [
    watch('propertyAddressLine1'),
    watch('propertyCity'),
    watch('propertyState'),
    watch('propertyPostalCode'),
  ]

  useEffect(() => {
    const postalCodeRegex = /^\d{5}(?:[-\s]\d{4})?$/;
    setCanStep(
      !isNullish(propertyAddressLine1) && 
      !isNullish(propertyCity) && 
      !isNullish(propertyState) && 
      !isNullish(propertyPostalCode) &&
      postalCodeRegex.test(propertyPostalCode)
    );
  }, [setCanStep, propertyAddressLine1, propertyCity, propertyState, propertyPostalCode]);
  
  return <div className='flex flex-col space-y-3 p-10 pt-10'>
    <label>
      <span className="block text-xs font-medium text-slate-gray pb-1">
        Address
      </span>
      <input name='propertyAddressLine1' id='propertyAddressLine1' type="text" className={`peer p-2 border rounded-xl focus:ring-offset-2 focus:outline-none ${errors.propertyAddressLine1 ? 'focus:ring-2 focus:ring-red-500' : 'focus:ring-2 focus:ring-blue-500'}`} defaultValue={getValues('propertyAddressLine1') || ''} {...register('propertyAddressLine1', { required: true })} />
      {touchedFields.propertyAddressLine1 && errors.propertyAddressLine1 && <p className='mt-1 text-xs text-red-400'>{errors.propertyAddressLine1.message}</p>}
    </label>
    <label>
      <span className="block text-xs font-medium text-slate-gray pb-1">
        Address Line 2
      </span>
      <input name='propertyAddressLine2' id='propertyAddressLine2' type="text" className={`peer p-2 border rounded-xl focus:ring-offset-2 focus:outline-none ${errors.propertyAddressLine2 ? 'focus:ring-2 focus:ring-red-500' : 'focus:ring-2 focus:ring-blue-500'}`} defaultValue={getValues('propertyAddressLine2') || ''} {...register('propertyAddressLine2', { required: false })} />
      {touchedFields.propertyAddressLine2 && errors.propertyAddressLine2 && <p className='mt-1 text-xs text-red-400'>{errors.propertyAddressLine2.message}</p>}
    </label>
    <label>
      <span className="block text-xs font-medium text-slate-gray pb-1">
        City
      </span>
      <input name='propertyCity' id='propertyCity' type="text" className={`peer p-2 border rounded-xl focus:ring-offset-2 focus:outline-none ${errors.propertyCity ? 'focus:ring-2 focus:ring-red-500' : 'focus:ring-2 focus:ring-blue-500'}`} defaultValue={getValues('propertyCity') || ''} {...register('propertyCity', { required: true })} />
      {touchedFields.propertyCity && errors.propertyCity && <p className='mt-1 text-xs text-red-400'>{errors.propertyCity.message}</p>}
    </label>
    <label>
      <span className="block text-xs font-medium text-slate-gray pb-1">
        State
      </span>
      <input name='propertyState' id='propertyState' type="text" className={`peer p-2 border rounded-xl focus:ring-offset-2 focus:outline-none ${errors.propertyState ? 'focus:ring-2 focus:ring-red-500' : 'focus:ring-2 focus:ring-blue-500'}`} defaultValue={getValues('propertyState') || ''} {...register('propertyState', { required: true })} />
      {touchedFields.propertyState && errors.propertyState && <p className='mt-1 text-xs text-red-400'>{errors.propertyState.message}</p>}
    </label>
    <label>
      <span className="block text-xs font-medium text-slate-gray pb-1">
        Postal Code
      </span>
      <input 
        name='propertyPostalCode' 
        id='propertyPostalCode' 
        type="text" 
        className={`peer p-2 border rounded-xl focus:ring-offset-2 focus:outline-none ${errors.propertyPostalCode ? 'focus:ring-2 focus:ring-red-500' : 'focus:ring-2 focus:ring-blue-500'}`} 
        defaultValue={getValues('propertyPostalCode') || ''} 
        {...register('propertyPostalCode', {
          required: true
        })} 
      />
      {touchedFields.propertyPostalCode && errors.propertyPostalCode && <p className='mt-1 text-xs text-red-400'>{errors.propertyPostalCode.message}</p>}
    </label>
  </div>;
}