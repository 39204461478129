import { useEffect } from "react";

export const PropDescription = ({ getValues, register, setCanStep, formState: { errors, touchedFields } }) => {

  useEffect(() => {
    setCanStep(true);
  }, [setCanStep]);

  return <div className='flex flex-col space-y-3 p-10 pt-10'>
    <label>
      <span className="block text-xs font-medium text-slate-gray pb-1">
        Enter a description of the property:
      </span>
      <textarea name='propertyDescription' id='propertyDescription' type="textarea" className={`peer p-2 border rounded-xl focus:ring-offset-2 focus:outline-none ${errors.propertyDescription ? 'focus:ring-2 focus:ring-red-500' : 'focus:ring-2 focus:ring-blue-500'}`} defaultValue={getValues('propertyDescription') || ''} {...register('propertyDescription', { required: false })} />
      {touchedFields.propertyDescription && errors.propertyDescription && <p className='mt-1 text-xs text-red-400'>{errors.propertyDescription.message}</p>}
    </label>
  </div>;
}