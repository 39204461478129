import { useEffect } from "react";

export const Directions = ({ getValues, register, setCanStep, formState: { errors, touchedFields } }) => {

  useEffect(() => {
    setCanStep(true);
  }, [setCanStep]);

  return <div className='flex flex-col space-y-3 p-10 pt-10'>
    <label>
      <span className="block text-xs font-medium text-slate-gray pb-1">
        Enter any relevant directions for the inspector:
      </span>
      <textarea name='directions' id='directions' type="textarea" className={`peer p-2 border rounded-xl focus:ring-offset-2 focus:outline-none ${errors.directions ? 'focus:ring-2 focus:ring-red-500' : 'focus:ring-2 focus:ring-blue-500'}`} defaultValue={getValues('directions') || ''} {...register('directions', { required: false })} />
      {touchedFields.directions && errors.directions && <p className='mt-1 text-xs text-red-400'>{errors.directions.message}</p>}
    </label>
  </div>;
}