import joi from 'joi';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';

export const useDrawItemForm = () => {
  const schema = joi.object({
    item: joi.string().required().messages({
      'any.required': 'Item name is required',
    }),
    description: joi.string().required().messages({
      'any.required': 'Description is required',
    }),
    budget: joi.number().min(1).required().messages({
      'any.required': 'Budget is required',
    }),
  });

  const form = useForm({
    resolver: joiResolver(schema),
  });
  
  return form;
}