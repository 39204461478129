import { useEffect } from "react";
import { Button } from "../../../../../components/Button";
import { useDrawItemForm } from "../../../../../hooks/useDrawItemForm";
import { amountToDollars } from "../../../../../utils";
import { TrashIcon } from '@heroicons/react/24/outline';
import CurrencyInput from 'react-currency-input-field';



export const DrawItems = ({ getValues, register, setValue, setCanStep, formState: { errors, touchedFields } }) => {

  useEffect(() => {
    setCanStep(true);
  }, [setCanStep]);

  const form = useDrawItemForm();

  const addDrawItem = () => {
    const item = form.watch();

    setValue('drawItems', [...(getValues('drawItems') || []), item]);
    form.reset({
      item: "",
      description: "",
      budget: "",
    });
  }

  const deleteDrawItem = (index) => {
    const newDrawItems = [...(getValues('drawItems') || [])];
    newDrawItems.splice(index, 1);
    setValue('drawItems', newDrawItems);
  }

  const drawItemRows = (getValues('drawItems') || []).map((item, index) => (
    <tr key={index}>
      <td>{item.item}</td>
      <td>{item.description}</td>
      <td>${amountToDollars(item.budget)}</td>
      <td>
        <button className="icon-button" onClick={() => deleteDrawItem(index)}>
          <TrashIcon className="h-5 w-5 text-red-500" />
        </button>
      </td>
    </tr>
  ));

  return <div className='flex flex-col space-y-3 p-10 pt-10'>
    <label>
      <span className="block text-xs font-medium text-slate-gray pb-1">
        Add draw items:
      </span>
    </label>

    <label>
      <span className="block text-xs font-medium text-slate-gray pb-1">
        Item
      </span>
      <input name='item' id='item' type="text" className={`peer p-2 border rounded-xl focus:ring-offset-2 focus:outline-none ${form.formState.errors.item ? 'focus:ring-2 focus:ring-red-500' : 'focus:ring-2 focus:ring-blue-500'}`} defaultValue={form.getValues('item') || ''} {...form.register('item', { required: true })} />
      {form.formState.touchedFields.item && form.formState.errors.item && <p className='mt-1 text-xs text-red-400'>{form.formState.errors.item.message}</p>}
    </label>

    <label>
      <span className="block text-xs font-medium text-slate-gray pb-1">
        Description
      </span>
      <textarea name='description' id='description' type="textarea" className={`peer p-2 border rounded-xl focus:ring-offset-2 focus:outline-none ${form.formState.errors.description ? 'focus:ring-2 focus:ring-red-500' : 'focus:ring-2 focus:ring-blue-500'}`} defaultValue={form.getValues('description') || ''} {...form.register('description', { required: true })} />
      {form.formState.touchedFields.description && form.formState.errors.description && <p className='mt-1 text-xs text-red-400'>{form.formState.errors.description.message}</p>}
    </label>

    <label>
      <span className="block text-xs font-medium text-slate-gray pb-1">
        Budget
      </span>
      <CurrencyInput
        name='budget'
        id='budget'
        className={`peer p-2 border rounded-xl focus:ring-offset-2 focus:outline-none ${form.formState.errors.budget ? 'focus:ring-2 focus:ring-red-500' : 'focus:ring-2 focus:ring-blue-500'}`}
        onValueChange={(value) => form.setValue('budget', (value * 100))}
        prefix="$"
        decimalScale={2}
        {...form.register('budget', { required: true })}
      />
      {form.formState.touchedFields.budget && form.formState.errors.budget && <p className='mt-1 text-xs text-red-400'>{form.formState.errors.budget.message}</p>}
    </label>
    
    <Button
      label='Add Item'
      onClick={addDrawItem}
      disabled={!form.formState.isValid}
    />

    {(getValues('drawItems') || []).length > 0 && (
      <table className="text-xs">
        <thead>
          <tr>
            <th>Item</th>
            <th>Description</th>
            <th>Budget</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {drawItemRows}
        </tbody>
      </table>
    )}

  </div>;
}