import { useState } from "react";
import { client } from '../../../../api';

import { Button } from "../../../../components/Button";
import { Card } from "../../../../components/Card";
import { WelcomeTitle } from "./Titles/Welcome";
import { ServicesTitle } from "./Titles/Services";
import { DrawItemsTitle } from "./Titles/DrawItems";
import { PropOwnerTitle } from "./Titles/PropOwner";
import { PropAddressTitle } from "./Titles/PropAddress";
import { PropDescriptionTitle } from "./Titles/PropDescription";
import { DirectionsTitle } from "./Titles/Directions";
import { useInspectionRequestForm } from "../../../../hooks/useInspectionRequestForm";
import { NoAccessTitle } from "./Titles/NoAccess";
import { Welcome } from "./Cards/Welcome";
import { Services } from "./Cards/Services";
import { DrawItems } from "./Cards/DrawItems";
import { NoAccess } from "./Cards/NoAccess";
import { Loading } from "../../../../components/Loading";
import { PropOwner } from "./Cards/PropOwner";
import { PropAddress } from "./Cards/PropAddress";
import { PropDescription } from "./Cards/PropDescription";
import { Directions } from "./Cards/Directions";
import { ConfirmTitle } from "./Titles/Confirm";
import { Confirm } from "./Cards/Confirm";
import { SuccessTitle } from "./Titles/Success";
import { Success } from "./Cards/Success";
import { useAuth } from "../../../../hooks/useAuth";

export const InspectionForm = () => {
  const [step, setStep] = useState(0);
  const [canStep, setCanStep] = useState(true);
  const [loading, setLoading] = useState(false);
  const [services, setServices] = useState({});
  const [total, setTotal] = useState(0);
  const [orderId, setOrderId] = useState(null);

  const form = useInspectionRequestForm();
  const {session, callback} = useAuth();

  if (!session) {
    return callback();
  }

  // Verify user has access to inspection service
  if (!session.accessToken.payload['cognito:groups'].includes('inspection')) {
    return <>
      <div className='flex flex-col justify-center items-center space-y-6 p-3'>
        <NoAccessTitle />
        <Card>
          <NoAccess />
        </Card>
      </div>
    </>;
  }

  const accessToken = session.accessToken.jwtToken;
  const email = session.idToken.payload.email;
  
  // @TODO: handleSubmit didn't call my submit function?
  // const { handleSubmit } = form;
  const onSubmit = async data => {
    setStep(s => s + 1);
    console.log("On Submit called");
    
    try {
      client.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
      const res = await client({
        method: 'post',
        url: '/api/inspection',
        data: {
          email,
          ...data,
        },
      });
  
      console.log(res);
  
      if (res.status === 200) {
        setOrderId(res.data.orderId);
        form.reset();
      }
    }
    catch (err) {
      // oops
      setStep(s => s - 1);
    }
  }

  const onNext = async () => {
    console.log(form.watch());
    console.log("CURRENT STEP:", step);

    if (step === 7) {
      console.log("Calling handle submit");
      setLoading(true);
      await onSubmit(form.watch());
      setLoading(false);
    }

    if (step !== 7) {
      setStep(s => s + 1);
    }

    if (step === 8) {
      setStep(1);
    }
  }

  const onBack = () => {
    setStep(s => s - 1);
  }


  return <>
    <div className='flex flex-col justify-center items-center space-y-6 p-3'>
      {[
        <WelcomeTitle />,
        <ServicesTitle />,
        <DrawItemsTitle />,
        <PropOwnerTitle />,
        <PropAddressTitle />,
        <PropDescriptionTitle />,
        <DirectionsTitle />,
        <ConfirmTitle />,
        <SuccessTitle />,
      ][step]}
      <Card>
        {[
          <Welcome />,
          <Services {...{ session, setLoading, services, setServices, setCanStep, total, setTotal, ...{ ...form }}}/>,
          <DrawItems {...{ setCanStep, ...{ ...form }}}/>,
          <PropOwner {...{ setCanStep, ...{ ...form}}} />,
          <PropAddress {...{ setCanStep, ...{ ...form}}} />,
          <PropDescription {...{ setCanStep, ...{ ...form}}} />,
          <Directions {...{ setCanStep, ...{ ...form}}} />,
          <Confirm {...{ services, total, setStep, setCanStep, ...{ ...form}}} />,
          <Success {...{ setCanStep, ...{ orderId }}} />,
        ][step]}
        {loading && <Loading />}
      </Card>
      <Button
        label={[
          'Get Started',
          'Continue',
          'Continue',
          'Continue',
          'Continue',
          'Continue',
          'Continue',
          'Submit',
          'Submit Another'
        ][step]}
        onClick={onNext}
        disabled={loading || !canStep}
      />
      <Button
        label="Back"
        onClick={onBack}
        disabled={loading}
        hidden={step === 0 || step === 8}
      />
    </div>
  </>
};