import { useState, useEffect } from 'react';
import { client } from '../../../../../api';

import { amountToDollars } from "../../../../../utils";

export const Services = ({session: {accessToken: { jwtToken }}, setLoading, services, setServices, setCanStep, total, setTotal, getValues, register, setValue, formState: { errors, touchedFields } }) => {
  const [selectedServices, setSelectedServices] = useState(getValues('services') || []);

  const [error, setError] = useState(null);

  useEffect(() => {
    setCanStep(selectedServices.length > 0);
  }, [selectedServices, setCanStep]);

  useEffect(() => {
    const fetchServices = async () => {
      try {
        client.defaults.headers.common['Authorization'] = `Bearer ${jwtToken}`;

        setLoading(true);
        const response = await client({
          method: 'get',
          url: '/api/inspection/services'
        });

        setServices(response.data);
        setLoading(false);
      } catch (error) {
        setError(error);
        console.log(error);
      }
    };

    if (Object.keys(services).length === 0) {
      fetchServices();
    }
  }, [jwtToken, setLoading, services, setServices]);

  const onSelectionChange = (event) => {
    const serviceName = event.target.name;
    const isChecked = event.target.checked;

    if (isChecked) {
      setSelectedServices([...selectedServices, serviceName]);
    } else {
      setSelectedServices(selectedServices.filter(name => name !== serviceName));
    }
  }

  const serviceItems = Object.keys(services).map(key => (
    <li key={key}>
      <label>
        <input type="checkbox" name={key} onChange={onSelectionChange} checked={selectedServices.includes(key)} />
        &nbsp;{services[key].service} || ${amountToDollars(services[key].charge)}
      </label>
    </li>
  ));

  useEffect(() => {
    setValue('services', selectedServices);
    setTotal(selectedServices.reduce((accumulator, serviceKey) => {
      return accumulator + services[serviceKey].charge;
    }, 0));
  }, [selectedServices, setValue, setTotal, services]);

  return <div className='flex flex-col space-y-3 p-10 pt-10'>
    <label>
      <span className="block text-xs font-medium text-slate-gray pb-1">
        Select the inspections you would like to be performed:
      </span>
    </label>
    {!error && <ul className="list-none text-xs font-medium text-slate-gray pb-1">
      {serviceItems}
    </ul>}

    {!error && <h1>Subtotal: ${amountToDollars(total)}</h1>}
  </div>;
}