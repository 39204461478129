import { AuthLinkRequest } from "../../sites/auth/views/AuthLinkRequest";

export const useAuth = () => {
  // Get auth session
  const sessionString = sessionStorage.getItem("easyhub-session");

  if (!sessionString) {
    // No user session, require login
    return {
      session: null,
      callback: AuthLinkRequest
    };
  }

  const session = JSON.parse(sessionString);
  // console.log("I have API access!!", session);

  const now = new Date().getTime() / 1000;
  if (session.accessToken.payload.exp <= (now)) {
    // Access token has expired, re-auth for now.
    // In the future, we would want to perform a token exchange here.

    // const refreshToken = session.refreshToken.token;
    return {
      session: null,
      callback: AuthLinkRequest
    };
  }

  return {
    session: session,
    callback: AuthLinkRequest
  };
}