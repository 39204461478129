import { useEffect } from "react";

export const Success = ({ orderId, setCanStep }) => {
  
  useEffect(() => {
    setCanStep(true);
  }, [setCanStep]);

  return <div className='flex flex-col space-y-3 p-10 pt-10'>
    <label>
      <span className="block text-xs font-medium text-slate-gray pb-1">
        We have received your order, your order ID is: 
        {"\n"}
        <strong>{orderId}</strong>
        {"\n"}
        Once your inspection(s) are complete, you will be emailed with your reports.
      </span>
    </label>
  </div>;
}