import { useEffect } from "react";
import { isNullish } from "../../../../../utils";

export const PropOwner = ({ getValues, register, watch, setCanStep, formState: { errors, touchedFields } }) => {

  const propertyOwner = watch('propertyOwner');

  useEffect(() => {
    setCanStep(!isNullish(propertyOwner));
  }, [setCanStep, propertyOwner]);

  return <div className='flex flex-col space-y-3 p-10 pt-10'>
    <label>
      <span className="block text-xs font-medium text-slate-gray pb-1">
        Enter the legal name of the property's owner:
      </span>
      <input name='propertyOwner' id='propertyOwner' type="text" className={`peer p-2 border rounded-xl focus:ring-offset-2 focus:outline-none ${errors.propertyOwner ? 'focus:ring-2 focus:ring-red-500' : 'focus:ring-2 focus:ring-blue-500'}`} defaultValue={getValues('propertyOwner') || ''} {...register('propertyOwner', { required: true })} />
      {touchedFields.email && errors.email && <p className='mt-1 text-xs text-red-400'>{errors.email.message}</p>}
    </label>
  </div>;
}